import React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import { Spinner } from '../spinner/spinner';

const variations = cva(
  'outline-action no-wrap relative flex items-center justify-center gap-0.5 no-underline outline-2 outline-offset-2 focus-visible:outline',
  {
    variants: {
      variant: {
        primary: `bg-action text-inverse child:fill-inverse hover:bg-action-dark disabled:bg-action-subtle disabled:text-action-subtle child-disabled:fill-action-subtle active:bg-action-darkest rounded-3xl`,
        secondary: `bg-action-inverse border-action text-action child:fill-action hover:border-action-dark hover:text-action-dark child-hover:fill-action-dark disabled:border-action-subtle disabled:text-action-subtle child-disabled:fill-action-subtle active:border-action-darkest active:text-action-darkest child-active:fill-action-darkest rounded-3xl border`,
        tertiary: `rounded-base text-action child:fill-action hover:text-action-dark child-hover:fill-action-dark disabled:text-action-subtle child-disabled:fill-action-subtle active:text-action-darkest child-active:fill-action-darkest`,
      },
      size: {
        large: 'font-ica-text leading-2xl h-[48px] px-3 text-lg font-bold',
        medium: 'font-ica-text leading-xl h-[40px] px-2.5 text-base font-bold',
        small: 'leading-l h-[32px] px-2 text-sm font-bold',
      },
      width: {
        full: 'w-full sm:max-w-[420px]',
        auto: 'w-auto',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
      width: 'auto',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    CustomVariantProps<typeof variations> {
  isLoading?: boolean;
  children?: React.ReactNode;
  asChild?: boolean;
}

/**
 *
 * @example
 * ```tsx
 * <Button variant="secondary" size="small">
 *  Hello World!
 * </Button>
 * ```
 *
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size,
      asChild,
      disabled,
      width,
      isLoading,
      className,
      onClick,
      children,
      ...rest
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const isPrimary = variant === 'primary';

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || isLoading) {
        e.preventDefault();
        return;
      }

      if (onClick) {
        onClick(e);
      }
    };

    return (
      <Comp
        disabled={disabled}
        className={cn(
          variations({ variant, size, width }),
          className,
          isLoading &&
            `child:fill-transparent child-hover:fill-transparent child-active:fill-transparent child-disabled:fill-transparent text-transparent hover:text-transparent active:text-transparent disabled:text-transparent`,
        )}
        onClick={handleOnClick}
        ref={ref}
        {...rest}
      >
        <Spinner
          className={`exclude-child absolute ${isLoading ? 'block' : 'hidden'}`}
          variant={isPrimary ? 'inverted' : 'default'}
          size={size}
        />
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button };
