// This component is meant for formatting price and label for lineitems and articles.
// TODO: Should be adjusted or removed when design system for typography is set.
import React from 'react';
import { Typography } from '@aph/ui/components/typography/typography';
import type { textColor } from '@aph/ui/tailwind/classes/text-color';
import { formatPrice } from '@aph/utilities/format-price/format-price';
import type { TextSize } from '~/styles/text-size';

export type PriceFormatterProps = {
  price: number;
  strikeThrough?: boolean;
  inline?: boolean;
  size?: TextSize;
  color?: `text-${keyof typeof textColor}`;
};

const priceSizeMapper: Partial<{ [textSize in TextSize]: string | null }> = {
  xsmall: 'text-sm', // 14px
  small: 'text-base', // 16px
  medium: 'text-2xl', // 24px
  large: 'text-3xl', // 28px
};

export const PriceFormatter = ({
  price,
  strikeThrough,
  inline = false,
  size = 'medium',
  color,
  ...props
}: PriceFormatterProps) => (
  <Typography
    color={color}
    className={`${priceSizeMapper[size]} ${size === 'xsmall' || size === 'small' ? 'font-ica-text' : 'font-ica-heading'} ${size === 'xsmall' || size === 'small' ? 'font-regular' : 'font-bold'} ${strikeThrough ? 'line-through' : 'none'} ${inline ? 'md:min-w-13' : 'none'}`}
    {...props}
  >
    {formatPrice(price)}
  </Typography>
);
