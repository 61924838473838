import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import { Icon, type IconName } from '../icon/icon';
import { Typography } from '../typography/typography';

const variations = cva('relative flex items-center justify-center rounded-full', {
  variants: {
    variant: {
      primary: `bg-action child:fill-inverse hover:bg-action-dark disabled:bg-action-subtle active:bg-action-darkest`,
      secondary: `bg-action-subtle child:fill-action child-hover:fill-action-dark disabled:bg-action-subtle child-disabled:fill-action-subtle active:text-action-darkest child-active:fill-action-darkest`,
      white: `bg-action-inverse child:fill-action child-hover:fill-action-dark disabled:bg-action-subtle child-disabled:fill-action-subtle active:text-action-darkest child-active:fill-action-darkest`,
    },
    size: {
      extraLarge: 'h-[48px] w-[48px]',
      large: 'h-[40px] w-[40px]',
      medium: 'h-[32px] w-[32px]',
      small: 'h-[24px] w-[24px]',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'large',
  },
});

interface ButtonCircleProps
  extends React.ButtonHTMLAttributes<HTMLElement>,
    CustomVariantProps<typeof variations> {
  icon: IconName;
  text?: string;
  badge?: React.ReactNode;
}

/**
 *
 * @example
 * ```tsx
 * <ButtonCircle variant="variant-property" icon="Plus" text="Click me">
 *  Hello World!
 * </ButtonCircle>
 * ```
 *
 */
export const ButtonCircle = React.forwardRef<HTMLButtonElement, ButtonCircleProps>(
  ({ icon, text, badge, variant, className, size = 'large', ...rest }, ref) => {
    return (
      <button
        type="button"
        className="outline-action flex flex-col items-center rounded-sm no-underline outline-2 outline-offset-2 focus-visible:outline"
        {...rest}
        ref={ref}
      >
        <div className={cn(variations({ variant, size }), className)}>
          <Icon name={icon} size={size} className="child" />
          {badge}
        </div>
        {text && (
          <Typography typography="footnote" className="mt-[2px] text-center">
            {text}
          </Typography>
        )}
      </button>
    );
  },
);
