import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import type { textColor } from '../../tailwind/classes/text-color';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';

const variations = cva('', {
  variants: {
    typography: {
      headingExtraLarge:
        'font-ica-heading leading-m text-4xl font-bold tracking-normal md:text-5xl',
      headingLarge:
        'font-ica-text leading-l md:leading-m text-3xl font-black tracking-tight md:text-4xl',
      headingMedium: 'font-ica-text leading-l text-2xl font-black tracking-tight',
      headingSmall: 'font-ica-text leading-xl text-lg font-bold tracking-normal',
      headingExtraSmall: 'font-ica-text leading-xl text-lg font-black tracking-tight',
      ingress: 'font-ica-text leading-xl text-lg font-bold tracking-tight',
      bodyBig: 'font-ica-text leading-l text-xl tracking-normal',
      body: 'font-ica-text leading-2xl text-base tracking-normal',
      subHeading: 'font-ica-text leading-xl font-regular text-sm tracking-normal',
      footnote: 'font-ica-text leading-2xl font-regular text-xs tracking-normal',
      priceLarge: 'font-ica-heading leading-s text-4xl font-bold tracking-normal',
      priceMedium: 'font-ica-heading leading-s text-3xl font-bold tracking-normal',
      priceSmall: 'font-ica-heading leading-s text-2xl font-bold tracking-normal',
      link: 'font-ica-text leading-2xl font-regular hover:text-action-dark focus:text-action-dark active:text-action-darkest text-base tracking-normal underline hover:underline focus:underline active:underline',
    },
  },
  defaultVariants: {
    typography: 'body',
  },
});

export interface TypographyProps
  extends React.HtmlHTMLAttributes<HTMLParagraphElement>,
    CustomVariantProps<typeof variations> {
  children?: React.ReactNode;
  asChild?: boolean;
  color?: `text-${keyof typeof textColor}`;
}

/**
 *
 * @example
 * ```tsx
 * <Typography typography="headingLarge" color="text-brand">
 *  Hello World!
 * </Typography>
 * ```
 *
 */
export const Typography: React.FC<TypographyProps> = ({
  children,
  typography,
  asChild,
  className,
  color = 'text-default',
  ...rest
}) => {
  const Comp = asChild ? Slot : 'p';
  return (
    <Comp className={cn(variations({ typography, className }), color)} {...rest}>
      {children}
    </Comp>
  );
};
